.confirm_body {
  background-color: transparent !important;
}

.confirm_message {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.5vmax !important;
  color: #01318D !important;
  text-align: center;
}

.confirm_sub_message {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.25vmax !important;
  color: #000 !important;
  text-align: center;
}