.plan_body {
  display: flex;
  flex-direction: column;
}

.consultation-product-card {
  flex: 0 0 auto;
  width: 30%;
  height: 65vh;
  margin: 0 1vmax;
}

.cp_cards_header {
  border-radius: 1vmax 1vmax 0 0;
  background: linear-gradient(180deg, #0146c7, #052d77);
  padding: 0.5vmax;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1.5vmax !important;
  color: #FFFF;
  height: 15vh;
  text-transform: uppercase;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cp_cards_body {
  font-family: 'PoppinsMedium' !important;
  color: #043080;
  height: 50vh;
  border-radius: 0 0 1vmax 1vmax;
  background-color: #FFFF;
  padding: 1vmax 0.5vmax;
  box-sizing: border-box;
  font-size: 1.25vmax !important;
  text-transform: uppercase;
  overflow-y: auto;
}

.cp_cards_header::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.cp_cards_header::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #FFFF;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.cp_cards_body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.cp_cards_body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #000;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.schedule-your-consultation {
  align-self: stretch;
  position: relative;
  font-size: 2vmax;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.consultation-icon-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 3vmax; 
  font-size: 2vmax;
}

.consultation-icon {
  height: 4.5vmax;
}

.consultation-plan {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  justify-content: flex-start;
  background-image: url(../../assets/img/college/background2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 2vmax;
  color: #FFFF;
  font-family: 'PoppinsMedium' !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail_container {
  background: linear-gradient(180deg, #0146c7, #052d77);
  min-width: 4.3vmax;
  min-height: 4.3vmax;
}

.mail_icon {
  color: white;
  font-size: 2.5vmax !important;
}
