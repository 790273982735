.c_payment_header {
    height: 15vh;
  }
  
  .cancellation_content {       
    justify-content: center;
  }
  
  .c_payment_banner {
    top: 3vmax;
    left: 0;
    background-color: #0146C7;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 4vmax;
    position: absolute;
    overflow: hidden;
  }
  
  .c_payment_logo {
    position: absolute;
    overflow: hidden;
    width: 12vmax;
    padding: 1vmax 2vmax;
    cursor: pointer;
  }
  
  
  .can_policy_header {
    font-family: 'PoppinsSemiBold' !important;
    font-size: 1.5vmax !important;
    color: #000 !important;
  }
  
  .can_policy_text {
    font-family: 'PoppinsRegular' !important;
    font-size: 1vmax !important;
    color: #000 !important;
    padding: 1vmax 1vmax;
  }
  .can_policy_text1 {
    font-family: 'PoppinsRegular' !important;
    font-size: 1vmax !important;
    color: #000 !important;
    padding-left: 1vmax ;
    padding-right: 1vmax;
  }