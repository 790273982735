.banner_div {
  background-color: #0146C7 !important;
  height: 100%;
  display: 'flex';
  justify-content: 'space-between';
  align-items: 'center';
}

.banner_typography {
  font-family: 'PoppinsMedium' !important;
  font-size: 1vmax !important;
  text-align: center !important;
  color: #FFFF;
  padding: 1vmax 0.5vmax;
}