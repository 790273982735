.story_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%
}

.story_card_override {
  height: 70vh;
}

.story_cards_header {
  border-radius: 1vmax 1vmax 0 0;
  background: linear-gradient(180deg, #0146c7, #052d77);
  padding: 0.5vmax 0;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2vmax !important;
  color: #FFFF;
  height: 15vh;
  text-transform: uppercase;
  overflow-y: auto; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.story_cards_body {
  font-family: 'PoppinsMedium' !important;
  color: #043080;
  height: 35vh;
  border-radius: 0 0 1vmax 1vmax;
  background-color: #FFFF;
  flex-shrink: 0;
  padding: 1vmax 0.5vmax;
  box-sizing: border-box;
  font-size: 1.5vmax !important;
  text-transform: uppercase;
  overflow-y: auto; 
}

.story_cards_header::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.story_cards_header::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:#FFFF ;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.story_cards_body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.story_cards_body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:#000 ;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.ss-frame-child {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 38vw;
  height: 0.5vmax;
}
.success-stories-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.success-stories {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 500;
}
.mobile-1-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  /* object-fit: cover; */
  z-index: 0;
  height: 95%;
}
.success-user-occupation,
.success-user {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  color: #000;
  font-size: 1.5vmax;
  overflow-wrap: anywhere;
}
.success-user-occupation {
  font-size: 1vmax;
}
.success-user-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 15vh;
  overflow-y: auto;
}
.review-text {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  display: inline-block;
  height: 35vh;
  overflow-y: auto; 
}
.review1 {
  width: 100%;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 0.85vmax;
  color: #00378b;
}

.ss-frame-group {
  margin: 0 !important;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 0 26%;
  gap:0.5vmax;
  top: 15%;
}
.mobile-1-parent {
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.ss-frame-parent {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: 1.5vmax;
  font-size: 2vmax;
  color: #000;
  height: 100%;
}
.success-story-main {
  margin-top: 2vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vmax;
}
.outcomes {
  position: relative;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-image: url(../../assets/img/success_stories/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 3vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
