.service_bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.service_logo {
  padding: 1vmax;
  height: 8vmax;
  width: 8vmax;
}

.multi-graph {
  width: 52vmax;
  height: 26vmax;
  position: absolute;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  box-sizing : border-box;
  &:before {
    content: '';
    width: 52vmax;
    height: 26vmax;
    border: 15vmax solid rgba(0,0,0,.15);
    border-top: none;
    position: absolute;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    border-radius: 0 0 52vmax 52vmax;
    left: 0;
    top: 0;
  }
  .graph {
    width: 52vmax;
    height: 26vmax;
    border: 15vmax solid var(--fill);
    border-top: none;
    position: absolute;
    transform-origin: 50% 0% 0;
    border-radius: 0 0 52vmax 52vmax;
    z-index: 5;
    transform: rotate( calc( 1deg * var(--percentage)  ) );
    box-sizing : border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: 1;
      &:after {
        opacity: 1;
      }
    }
  }
}

.service_text {
  text-transform: uppercase;
  color: #FFFFFF !important;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1vmax !important;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
  /* transform: rotate( calc( -1deg * var(--percentage)));  */
  z-index: 5;
}

.text1 {
  position: absolute;
  top: 8%;
  left: 30%;
}

.text2 {
  position: absolute;
  top: 29%;
  left: 40%;
}

.text3 {
  position: absolute;
  top: 29%;
  left: 55%;
}

.text4 {
  position: absolute;
  top: 8%;
  left: 65%;
}

.g0 {
  --percentage : 0;
  --fill: #6DC7F8 ;
}

.g1 {
  --percentage : 42.5;
  --fill: #019DF0 ;
}

.g2 {
  --percentage : 90;
  --fill: #0175DE ;
}

.g3 {
  --percentage : 137.5;
  --fill: #014B92 ;
}


.split-screen {
  display: flex;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}

.split-bottom-left, .split-bottom-right {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.split-bottom-left::before, .split-bottom-right::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.5s;
}

.split-bottom-left::before {
  background-color: transparent;
  transform: skewY(-45deg);
  transform-origin: bottom right;
}

.split-bottom-right::before {
  background-color: transparent;
  transform: skewY(45deg);
  transform-origin: bottom left;
}

.split-bottom-left {
  background-color: transparent;
}

.split-bottom-right {
  background-color: transparent;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.content-left {
  left: 75%;
  opacity: 0;
  transition: opacity 0.5s;
}

.content-right {
  left: 25%;
  opacity: 0;
  transition: opacity 0.5s;
}

.content-remaining-left {
  top: 25%;
  left: 25%;
  opacity: 0;
  transition: opacity 0.5s;
}

.content-remaining-right {
  top: 25%;
  left: 75%;
  opacity: 0;
  transition: opacity 0.5s;
}
/* 
.split-bottom-left:hover .content-left,
.split-bottom-right:hover .content-right {
  opacity: 1;
}

.split-bottom-left:hover .content-remaining-left,
.split-bottom-right:hover .content-remaining-right {
  opacity: 1;
} */