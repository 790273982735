.academics-test,
.framework-for-improvement {
  align-self: stretch;
  flex: 1;
  position: relative;
  text-transform: uppercase;
}
.framework-for-improvement {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.academics-test {
  font-size: 1.5vmax;
  color: #000;
}
.framework-for-improvement-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.process-1-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.process-frame,
.process {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process-frame {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
}
.process {
  background-color: #FFFF;
  width: 100%;
  overflow: hidden;
  padding: 2vmax;
  box-sizing: border-box;
  justify-content: flex-start;
  text-align: center;
  font-size: 3vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
}
