.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
  }
  
  .call-to-action {
    background-color: #62CE94;
    max-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  