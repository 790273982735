.resource_bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.3;
  filter: blur(3px);
}

.resource_item_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  z-index: 1;
  text-align: center;
  justify-content: center;
}

.resource_item_bottom {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
  padding-left: 5%;
  overflow-y: auto; 
}

.resource_item_bottom::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.resource_item_bottom::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:#000 ;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.resource_header {
  font-family: 'PoppinsMedium' !important;
  font-size: 3vmax !important;
  letter-spacing: 0.5vmax !important;
  color: #001D73 !important;
}

.resource_icon {
  font-family: 'PoppinsMedium' !important;
  color: #001D73 !important;
  height: 1.5vmax !important;
  width: 1.5vmax !important;
}

.resource_link {
  font-family: 'PoppinsMedium' !important;
  color: #001D73 !important;
  font-size: 1.5vmax !important;
  text-decoration: none !important;
}
