.resetpwd_header {
  height: 15vh;
}

.resetpwd_content {
  height: 85vh;
  display: flex;
  justify-content: center;
}

.resetpwd_banner {
  top: 3vmax;
  left: 0;
  background-color: #0146C7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 4vmax;
  position: absolute;
  overflow: hidden;
}

.resetpwd_logo {
  position: absolute;
  overflow: hidden;
  width: 12vmax;
  padding: 1vmax 2vmax;
  cursor: pointer;
}

.resetpwd_left {
  width: 60%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resetpwd_right {
  width: 40%;
  align-self: center;
  text-align: center;
}

.left_image {
  width: 90%;
  object-fit: contain;
  align-self: flex-end; 
}

.hello_text {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2vmax !important;
  color: #000 !important;
}

.sign_in_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1.5vmax !important;
  color: #000 !important;
}

.social_icon_list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 4% 20%;
}

.social_icon {
  height: 2vmax;
  width: 2vmax;
}

.botton_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 4% 20%;
}

.input_field {
  color: #000 !important;
  width: 60% !important;
  align-self: center !important;
}

.forget_pwd_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1vmax !important;
  color: #000 !important;
}