.road_bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 180vh !important;
  object-fit: cover;
  z-index: 0;
  padding-left: 1.5vmax;
}

.road_path_flow_image {
  width: 35vw;
  margin-left: 2vmax;
}


.quicklinksMain {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10vmax 1.5vmax; 
}