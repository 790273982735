.international_bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}

.international_item_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%;
  z-index: 1;
  text-align: center;
  justify-content: center;
}

.international_item_2 {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 85%;
  z-index: 1;
  padding: 2%;
}

.international_header {
  font-family: 'PoppinsMedium' !important;
  font-size: 3vmax !important;
  color: #0146C7;
}

.international_text {
  font-family: 'PoppinsMedium' !important;
  color: #001D73 !important;
  font-size: 1.4vmax !important;
  text-transform: uppercase;
  text-align: center;
}