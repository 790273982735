.take-a-diagnostic {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.dia-frame-item {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 32vw;
  height: 0.5vmax;
}
.take-a-diagnostic-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.dia_icon.coming-soon {
  filter: grayscale(100%);
  height: 3.25vmax !important;
  width: 3.25vmax !important;
}

.diagnostics-text {
  display: flex;
  align-items: center;
}

.diagnostics-text.coming-soon {
  color: #999;
  /* margin-left: 3vmax; */
}

.comingSoon {
  font-size: 0.60vmax;
  color: #0146c7;
}

.tree-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin-top: -0.5vmax;
  height: 50vh;
}
.digital-sat-prep {
  align-self: stretch;
  font-weight: 500;
}
.diagnostics,
.digital-sat-prep,
.div5 {
  position: relative;
  text-transform: uppercase;
}
.div5 {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.diagnostics {
  font-size: 1.25vmax;
  font-weight: 500;
  font-family: 'PoppinsMedium' !important;
  color: #000;
}
.act-diagnostics-parent,
.digital-sat-prep-parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1vmax;
}
.dia-frame-inner {
  flex: 1;
  position: relative;
  background-color: transparent;
}
.dia-frame-parent-test {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2vmax;
  font-size: 1.5vmax;
  color: #12007b;
}
.dia-frame-parent3,
.dia-frame-parent5 {
  display: flex;
  align-items: center;
}
.dia-frame-parent5 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  font-size: 1.5vmax;
  padding: 0 5vmax;
  color: #000;
}
.dia_icon {
  height: 3.25vmax !important;
  width: 3.25vmax !important;
}
.dia-frame-parent3 {
  flex: 1;
  flex-direction: column;
  padding: 2vmax;
  padding-top: 3vmax;
  justify-content: flex-start;
  gap: 1vmax;
}
.take-diagnostic,
.take-diagnostic-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.take-diagnostic-inner {
  flex: 1;
  justify-content: flex-start;
}
.take-diagnostic {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1vmax 0;
  box-sizing: border-box;
  justify-content: center;
  background-image: url(../../assets/img/diagnostic/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 3vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
}