.payment_header {
  height: 15vh;
}

.payment_heading {
  font-family: 'PoppinsRegular' !important;
  font-size: 2vmax !important;
  color: #000 !important;
  text-align: center;
}

.payment_content {
  height: 78vh;
  display: flex;
  justify-content: center;
}

.payment_banner {
  top: 3vmax;
  left: 0;
  background-color: #0146C7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 4vmax;
  position: absolute;
  overflow: hidden;
}

.payment_logo {
  position: absolute;
  overflow: hidden;
  width: 12vmax;
  padding: 1vmax 2vmax;
  cursor: pointer;
}

.payment_left {
  width: 60%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2vmax;
}

.payment_right {
  width: 40%;
  align-self: center;
  text-align: center;
  margin-right: 2vmax;
}

.left_image {
  width: 90%;
  object-fit: contain;
  align-self: flex-end; 
}

.payment_bill {
  width: 80%;
}

.payment_bill_header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1.5vmax !important;
  color: #0146C7 !important;
  text-align: center;
}

.payment_bill_txt {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1vmax !important;
  color: #000 !important;
}

.hello_text {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2vmax !important;
  color: #000 !important;
}

.sign_in_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1.5vmax !important;
  color: #000 !important;
}

.failed_payment_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1vmax !important;
  font-style: italic !important;
  color: #ff0000 !important;
}

.payment_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1vmax !important;
  color: #000 !important;
}

mark {
  -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
          animation: 1.5s highlight 1.5s 1 normal forwards;
  background-color: none;
  background: linear-gradient(90deg, #7efff5 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}