.appbar {
  background-color: #FFF !important;
}

.toolbar {
  display: 'flex';
  justify-content: 'space-between';
  align-items: 'center';
}

.logo_img {
  width: 3vmax;
  margin-top: 0.25vmax;
  cursor: pointer;
}

.menu_item {
  text-transform: uppercase;
  color: #0146C7 !important;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1.25vmax !important;
  text-decoration: none !important;
  transition: scroll-behavior 0.5s ease-in-out;
}

.background_color {
  background: #0146C7 !important;
}

.icon_btn {
  margin: 5px !important;
}

.icon {
  font-size: 1.5vmax !important;
}

.avator_icon {
  font-size: 1.25vmax !important;
  background: #0146C7 !important;
  height: 2vmax !important;
  width: 2vmax !important;
}

.shopping_icon {
  color: #0146C7 !important;
  border: 1px solid #0146C7 !important;
}

.phone_link {
  text-decoration: none !important;
  color: #FFF !important;
}

.phone_icon {
  margin-right: 0 !important;
}

.nav_typography {
  margin: 5px !important;
  margin-left: -5px !important;
  padding: 1px 20px !important;
  font-family: 'PoppinsRegular' !important;
  font-size: 0.95vmax !important;
}

.menu_item {
  text-transform: uppercase;
  color: #0146C7 !important;
  font-family: var(--font-poppins) !important;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 1.30vmax !important;
  text-decoration: none !important;
  transition: scroll-behavior 0.5s ease-in-out;
}

.popup_menu_item {
  font-family: var(--font-poppins) !important;
  font-size: 1vmax !important;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 1vmax !important;
  color: #FFF !important;
  text-transform: uppercase;
}
.popup_menu_item:hover {
  color: #0146C7 !important;
  background-color: #FFF !important;
}

.dashboardLink ,.reportsLink {
  text-decoration: none;
}

.frame-group {
  font-family: var(--font-poppins);
  margin-left: 5%;
}

.hello-john-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  width: 100%
}

.hello-john-txt-container {
  font-weight: bold;
}

.hello-john-txt-container span {
  font-weight: 500; /* semi-bold */
}

.good-morning {
  font-weight: 300; /* light */
  white-space: nowrap; /* Prevent greeting from wrapping */
}