.make_payment_header {
    height: 15vh;
  }
  
  .make_payment_content {
    height: 50vh;
    display: flex;
    justify-content: center;
    padding: 2.5% 10%;
    gap: 5vmax
  }
  
  .make_payment_banner {
    top: 3vmax;
    left: 0;
    background-color: #0146C7;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 4vmax;
    position: absolute;
    overflow: hidden;
  }
  
  .make_payment_logo {
    position: absolute;
    overflow: hidden;
    width: 12vmax;
    padding: 1vmax 2vmax;
    cursor: pointer;
  }
  
  .make_payment_left {
    width: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column; 
    height: 50%;
    /* flex: 1;
    display: flex;
    flex-direction: row; */
    
    /* justify-content: space-between; */
  }
  
  .make_payment_right {
    width: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column; 
    height: 70%;
     /* align-self: center;
    text-align: center;  */
    /* margin-right: 2vmax; */
  }
 .make_payment_buttons{
    flex: 1;
    display: flex;
    flex-direction: row; 
    padding-top:5%;
    justify-content: flex-end;
    gap: 2vmax
  }
  .make_payment_text {        
    color: #000 !important;
  }
  
  .left_image {
    width: 90%;
    object-fit: contain;
    align-self: flex-end; 
  }
  
  .hello_text {
    font-family: 'PoppinsSemiBold' !important;
    font-size: 2vmax !important;
    color: #000 !important;
  }
  
  .sign_in_text {
    font-family: 'PoppinsRegular' !important;
    font-size: 1.5vmax !important;
    color: #000 !important;
  }
  
  .make_payment_text {
    font-family: 'PoppinsRegular' !important;
    font-size: 1vmax !important;
    color: #000 !important;
  }
  .confirm_dialog_title{
    font-size: 1.2vmax !important; 
    padding-left: 10px !important;
    color: #000 !important;
  }
  .confirm_dialog_body{
   
    width: 90% !important;
  }
  .confirm_dialog_msg{        
    font-size: 0.9vmax !important;
    color: #000 !important;
  }
  .makepayment_footer_info{
    background-color: #E7E7E7 !important;    
    color: #000;
    margin-top: 10px;  
    padding: 10px !important;   
    text-align: center;
    font-size: 0.8vmax !important; 
  }
  .makepayment_footer_text{
    margin: 5px;
    padding: 0.8%;
    background-color: #62ce94 !important;
    color: #fff;
  }
  .credit_card_logo{
    display: flex;
    padding: 1vmax 2vmax;
    justify-content: flex-end !important;
  }
  .payment_info{
    color: #000;
    display: flex;
    padding: 1vmax 2vmax;
    justify-content: flex-end !important;
  }
  .make_payment_cancel{
    color:#44f;
    padding-top: 1vh;
    /* display: flex; */
    align-items:center
  }