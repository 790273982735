.registration_header {
  height: 15vh;
}

.registration_content {
  height: 85vh;
  display: flex;
  justify-content: center;
}

.registration_banner {
  top: 3vmax;
  left: 0;
  background-color: #0146C7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 4vmax;
  position: absolute;
  overflow: hidden;
}

.registration_logo {
  position: absolute;
  overflow: hidden;
  width: 12vmax;
  padding: 1vmax 2vmax;
  cursor: pointer;
}

.registration_welcome {
  font-family: 'PoppinsBold' !important;
  font-size: 2vmax !important;
  text-align: center;
  color: #000;
}

.registration_summary {
  font-family: 'PoppinsRegular' !important;
  font-size: 1.25vmax !important;
  text-align: center;
  color: #000;
}

.reg_accordian_summary {
  background-color: #0146C7 !important;
}

.reg_accordian_icon {
  color: #FFFF !important;
  font-size: 2vmax !important;
}

.reg_accordian_header {
  margin-left: 1vmax !important;
  font-family: 'PoppinsMedium' !important;
  font-size: 1.25vmax !important;
  color: #FFFF !important;
}

.reg_accordian_asterisk {
  color: #FF0000 !important;
}

.reg_section_header {
  font-family: 'PoppinsBold' !important;
  font-size: 0.75vmax !important;
  color: #000 !important;
}

.inputValue{
  font-family: 'PoppinsBold' !important;
  font-size: 0.75vmax !important;
  color: #000 !important;
}

.registration_policy {
  font-family: 'PoppinsRegular' !important;
  font-size: 0.6vmax !important;
  color: #000;
}

.dd_input_label {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1vmax !important;
  color: var(--primary-font-color) !important;
  opacity: 0.5;
  font-weight: 400;
}

.dd_input_label_focused,
.dd_input_label_shrink {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 0.85vmax !important;
  color: var(--primary-font-color) !important;
  opacity: 1 !important;
  margin-left: 0.5vmax;
  margin-right: 1vmax
}  