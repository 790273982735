.road_path_image {
  position: absolute;
  top: 5vmax;
  left: 0;
  width: 100%;
  height: 100vh !important;
  object-fit: contain;
  z-index: 0;
  padding-left: 1.5vmax;
  padding-top: 2.5vmax;
  padding-bottom: 1.5vmax;
}

.header_Text_Container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5vmax;
  gap: 0.5vmax;
}

.header_text{
  font-family: 'PoppinsMedium' !important;
  font-size: 2vmax !important;
  color: #01318D;
}

.header_sub_text{
  color: #01318D;
  font-family: 'PoppinsRegular' !important;
  font-size: 1.3vmax !important;
}

.road_bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  object-fit: cover;
  z-index: 0;
  padding-left: 1.5vmax;
}

.road_map_header_text {
  font-family: 'PoppinsMedium' !important;
  font-size: 2vmax !important;
  color: #000;
  margin: 0 3vmax !important;
}


.road_map_avatar_1 {
  background-color: white !important;
  font-family: 'PoppinsMedium' !important;
  font-size: 2.75vmax !important;
  width: 6vmax !important;
  height: 6vmax !important;
  border: 10px solid #6dc7f8 !important;
  color: #6dc7f8 !important;
}

.road_map_avatar_2 {
  background-color: white !important;
  font-family: 'PoppinsMedium' !important;
  font-size: 2.75vmax !important;
  width: 6vmax !important;
  height: 6vmax !important;
  border: 10px solid #019DF0 !important;
  color: #019DF0 !important;
}

.road_map_avatar_3 {
  background-color: white !important;
  font-family: 'PoppinsMedium' !important;
  font-size: 2.75vmax !important;
  width: 6vmax !important;
  height: 6vmax !important;
  border: 10px solid #0175DE !important;
  color: #0175DE !important;
}

.road_map_avatar_4 {
  background-color: white !important;
  font-family: 'PoppinsMedium' !important;
  font-size: 2.75vmax !important;
  width: 6vmax !important;
  height: 6vmax !important;
  border: 10px solid #014B92 !important;
  color: #014B92 !important;
}

.road_map_indicator_1 { 
  position: absolute;
  padding: 0 !important;
  cursor: pointer;
  left: 27.5% !important;
  top: 11% !important;
}

.road_map_indicator_2 {
  position: absolute;
  padding: 0 !important;
  cursor: pointer;
  left: 54.5% !important;
  top: 11% !important;
}

.road_map_indicator_3 {
  position: absolute;
  padding: 0 !important;
  cursor: pointer;
  left: 47% !important;
  top: 36% !important;
}

.road_map_indicator_4 {
  position: absolute;
  padding: 0 !important;
  cursor: pointer;
  left: 71% !important;
  top: 63% !important;
}

.college_sign_board {
  position: absolute;
  left: 27.5% !important;
  bottom:7.5% !important;
  width: 15%;
  height: 15%;
}

.road_map_grade {
  font-size: 0.65vmax;
}

.road_map_ordinal {
  font-size: 0.75vmax;
  align-self: baseline;
  margin-top: 0.5vmax;
  margin-left: -0.5vmax;
}

.roadmap_icon {
  height: 2vmax !important;
  width: 2vmax !important;
  color: white;
  margin: 0 1vmax;
}

.roadmap_popup_body {
  width: 30vw !important;
  padding: 0.75vmax;
}

.road_popup_text {
  color: #000 !important;
  font-family: 'PoppinsBold' !important;
  font-size: 1vmax !important;
}

.road_blue_text {
  color: #0146C6 !important;
}

/* sup {
  vertical-align: super;
  font-size: smaller;
}  */

.road_blue_text_heading {
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.road_popover {
  transition: 'transform 0.3s ease-out';
}


@media (max-width: 1872px) and (min-width: 1572px) {
  .road_map_indicator_1 {
    left: 28% !important;
    top: 11% !important;
  }

  .road_map_indicator_2 {
    left: 54.5% !important;
    top: 11% !important;
  }

  .road_map_indicator_3 {
    left: 47% !important;
    top: 37% !important;
  }

  .road_map_indicator_4 {
    left: 69% !important;
    top: 60% !important;
  }
}

@media (max-width: 1572px) and (min-width: 1440px) {
  .road_map_indicator_1 {
    left: 27% !important;
    top: 11% !important;
  }

  .road_map_indicator_2 {
    left: 55.5% !important;
    top: 11% !important;
  }

  .road_map_indicator_3 {
    left: 48% !important;
    top: 37% !important;
  }

  .road_map_indicator_4 {
    left: 73% !important;
    top: 60% !important;
  }
}

@media (max-width: 1400px) and (min-width: 1344px) {
  .road_map_indicator_1 {
    left: 25% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_2 {
    left: 54.5% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_3 {
    left: 47% !important;
    top: 30% !important;
  }

  .road_map_indicator_4 {
    left: 77% !important;
    top: 60% !important;
  }
}

@media (max-width: 1344px) and (min-width: 1268px) {
  .road_map_indicator_1 {
    left: 22% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_2 {
    left: 57.5% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_3 {
    left: 49% !important;
    top: 30.5% !important;
  }

  .road_map_indicator_4 {
    left: 78% !important;
    top: 60% !important;
  }
}

@media (max-width: 1268px) and (min-width: 1168px) {
  .road_map_indicator_1 {
    left: 20% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_2 {
    left: 57.5% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_3 {
    left: 49% !important;
    top: 31% !important;
  }

  .road_map_indicator_4 {
    left: 80% !important;
    top: 61% !important;
  }
}

@media (max-width: 1168px) and (min-width: 1046px) {
  .road_map_indicator_1 {
    left: 17% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_2 {
    left: 57.5% !important;
    top: 1.5% !important;
  }

  .road_map_indicator_3 {
    left: 48% !important;
    top: 31.5% !important;
  }

  .road_map_indicator_4 {
    left: 83% !important;
    top: 62% !important;
  }
}

@media (max-width: 1046px) and (min-width: 920px) {
  .road_map_indicator_1 {
    left: 20% !important;
    top: 2.3% !important;
  }

  .road_map_indicator_2 {
    left: 59% !important;
    top: 2% !important;
  }

  .road_map_indicator_3 {
    left: 48% !important;
    top: 32% !important;
  }

  .road_map_indicator_4 {
    left: 79% !important;
    top: 62% !important;
  }
}

