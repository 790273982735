.dd_input_label.MuiInputLabel-root {
  color: var(--primary-font-color) !important;
}

  .dd_field {
      width: 100%;
  }

  .dd_input_label {
    font-family: 'PoppinsSemiBold' !important;
    font-size: 1vmax !important;
    color: var(--primary-font-color) !important;
    opacity: 0.5;
    font-weight: 400;
  }
  
  .dd_input_label_focused,
  .dd_input_label_shrink {
    font-family: 'PoppinsSemiBold' !important;
    font-size: 0.85vmax !important;
    color: var(--primary-font-color) !important;
    opacity: 1 !important;
    margin-left: 0.5vmax;
    margin-right: 1vmax
  }  

  .dd_input_notch_line {
    border: 2px solid #0146C6 !important;
  }
  
  .dd_menuPaper {
		max-height: 25vh !important
	}

  /* Customize as needed */
  .dd_field .MuiInputBase-root,
  .dd_field .MuiSelect-root {
    font-family: 'PoppinsSemiBold';
    font-size: 1vmax;
    color: var(--primary-font-color);
    border-radius: 2vmax;
  }
  
  .dd_field .MuiSelect-root:focus {
    background: transparent;
  }
  
  .dd_field .MuiSelect-selectMenu {
    padding: 10px; /* Adjust padding as needed */
  }
  
  .dd_field .MuiInputLabel-root {
    font-family: 'PoppinsSemiBold';
    font-size: 1vmax;
    color: var(--primary-font-color);
    opacity: 0.5;
  }
  
  .dd_field .MuiInputLabel-root.Mui-focused {
    font-size: 0.85vmax;
    opacity: 1;
    margin-left: 0.5vmax;
    color: var(--primary-font-color);
  }
  
  .dd_field .MuiInputLabel-root.Mui-shrink {
    font-size: 0.85vmax;
    opacity: 0.5;
    font-weight: 400;
    margin-left: 5px;
    color: var(--primary-font-color);
  }
  
  .dd_field .MuiSelect-icon {
    color: var(--primary-font-color);
  }

  .dd_input_text, 
  .dd_input_text_center, 
  .dd_input_header, 
  .dd_input_header_center {
    font-family: 'PoppinsSemiBold' !important;
    font-size: 7vmax !important;
    color: var(--primary-font-color) !important;
    border-radius: 2vmax !important;
  }
  