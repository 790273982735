.wait_stack {
  position: absolute;
  background-color: #000 !important;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.wait_icon {
  color: #FFF !important;
}

.wait_text {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.5vmax !important;
  color: #FFF !important;
  text-align: center;
}