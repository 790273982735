.test_body {
  padding: 1vmax;
  display: flex;
  flex-direction: column;
  color: #000;
}

.test_header_parent {
  margin-left: 1vmax;
}

.test_header {
  font-family: 'PoppinsMedium' !important;
  font-size: 2vmax !important;
  color: #01318D !important;
}

.test_header_right {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.5vmax !important;
  color: #01318D;
  cursor: pointer;
  text-decoration: underline;
}

.test_header_right:hover {
  color: #000;
}