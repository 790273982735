.enroll_body {
  padding: 1vmax;
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/img/custom_class/enrollBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* min-height: 90%; */
}

.enroll_header_parent {
  display: flex;
  margin-left: 1vmax; 
}

.product-container {
  padding: 0 0vw 0 4vw;
  display: flex;
  overflow-x: auto;
  overflow-y: auto; 
  scroll-behavior: smooth;
}

.product-card {
  flex: 0 0 auto;
  width: 30%;
  height: 70vh;
  margin: 0 1vmax;
}

.enroll_header {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.5vmax !important;
  color: #0244CB !important;
  /* margin-top: 1vmax !important; */
}

.enroll_text {
  font-family: 'PoppinsRegular' !important;
  color: #000 !important;
  font-size: 1.25vmax !important;
}

.enroll_input {
  width: 20vw;
  border-radius: 2vmax !important;
  color: #0146C7;
  background-color: #FFFF !important;
  margin-left: 2vmax !important;
}

.enroll_input_text {
  font-family: 'PoppinsMedium' !important;
  font-size: 1vmax !important;
  color: #0146C7 !important;
  border: 2.5px solid #0146C7 !important;
  border-radius: 2vmax !important;
}

.enroll_input_label {
  color: #000 !important;
}

.enroll_icon_bg {
  background-color: #0146C7 !important;
}

.enroll_icon {
  color: #0146C7 !important;
}

.enroll_input_notch_line {
  border: none !important;
}

.course_cards_container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.course_cards {
  border: 1px solid #043897;
  border-radius: 1vmax !important;
}

.course_cards_stack {
  margin-bottom: 1vmax;
}

.course_cards_header {
  background: linear-gradient(180deg, #0145C3 0%, #06307F 100%);
  overflow-y: auto;
  height: 7vh;
}

.course_cards_header_txt {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 0.85vmax !important;
  color: #FFFF;
}

.course_details_header {
  background-color: #FFFF;
  overflow-y: auto;
  height: 10vh;
}

.course_details_txt {
  font-family: 'PoppinsMedium' !important;
  font-size: 0.7vmax !important;
  color: #0B3D9A;
}

.course_details_icon {
  font-family: 'PoppinsMedium' !important;
  font-size: 1vmax !important;
  color: #0B3D9A;
}

.course_schedule_stack {
  background: linear-gradient(180deg, #0145C3 0%, #06307F 100%);
  height: 25vh;
}

.course_schedule_header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 0.7vmax !important;
  color: #FFFF;
  padding: 0.5vmax 0;
}

.course_schedule_txt {
  font-family: 'PoppinsMedium' !important;
  font-size: 0.7vmax !important;
  color: #FFFF;
}

.course_schedule_summary_stack {
  /* padding: 0.5vmax 2vmax; */
  height: 14vh;
  overflow-y: auto;
}

.course_schedule_summary {
  font-family: 'PoppinsMedium' !important;
  font-size: 0.65vmax !important;
  color: #462929;
  padding: 0.25vmax 2vmax;
}

.course_contact_stack {
  padding: 0 2vmax;
  height: 5vh;
  overflow-y: auto;
}

.course-schedule {
  height: 20vh !important;
  margin: 0 !important;
  width: 100%;
  overflow-y: auto;
}

.course-schedule::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.course-schedule::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: white ;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}