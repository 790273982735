.tutor_accordian_summary {
  background: linear-gradient(180deg, #0146c7, #052d77);
  border-radius: 2vmax !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.tutor_accordian_icon {
  color: #FFFF !important;
  font-size: 2vmax !important;
}

.tutor_accordian_header {
  margin-left: 1vmax !important;
  font-family: 'PoppinsMedium' !important;
  font-size: 1.75vmax !important;
  color: #FFFF !important;
  text-transform: uppercase;
}

.tutor_accordian_detail {
  background-color: #FFFF;
}

.tutor_accordian_detail_stack {
  margin: 1vmax;
}

.tutor_accordian_detail_text {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.5vmax !important;
  text-transform: uppercase;
  color: #0143BF !important;
}

.tutor_sub_page {
  gap: 1vmax;
}

.tutor_sub_page_header {
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
}

.tutor_sub_page_summary_div {
  flex: 1;
  position: relative;
  text-transform: uppercase;
  margin: 0 2vmax;
  gap: 1vmax;
}

.tutor_sub_page_summary {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.25vmax;
  color: #000;
  font-family: 'PoppinsMedium' !important;
}

.tutor_sub_page_photo_div {
  border-radius: 50%;
}

.tutor_sub_page_photo {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}

.pt-frame-child {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 30vw;
  height: 0.5vmax;
}
.private-tutoring-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.private-tutoring-header{
  text-transform: uppercase;
}
.academic-tutoring-wrapper,
.personalized-programs-wrapper,
.standardized-test-prep-wrapper {
  align-self: stretch;
  border-radius: 1vmax;
  background: linear-gradient(180deg, #0146c7, #052d77);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 1vmax 4.5vmax;
  align-items: center;
  justify-content: center;
}
.academic-tutoring-wrapper,
.personalized-programs-wrapper {
  padding: 1vmax 5vmax;
}
.personalized-programs-wrapper {
  padding: 1vmax 4vmax;
}
.menuitems {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1vmax 1vmax;
  justify-content: flex-start;
  align-items: center;
}
.every-student-is-unique {
  margin: 0;
}
.our-expert-tutors {
  margin: 0;
  font-size: 1.25vmax;
  font-weight: 500;
}
.every-student-is-container {
  flex: 1;
  position: relative;
  text-transform: uppercase;
}
.every-student-is-unique-our-e-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 0 1vmax;
  align-items: flex-end;
  justify-content: space-between;
}
.photoframe-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 2vmax;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.pt-frame-parent5,
.menuitems-parent {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.pt-frame-parent5 {
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.5vmax;
  color: #000;
}
.menuitems-parent {
  flex-direction: row;
  padding: 1vmax 0 0;
  align-items: flex-start;
  font-size: 2vmax;
  color: #FFFF;
}
.pt-frame-parent4,
.private-tutoring {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.pt-frame-parent4 {
  align-self: stretch;
  flex: 1;
  gap: 4vmax;
  padding: 4vmax 0 0;
}
.private-tutoring {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url(../../assets/img/private_tutoring/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 3vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
}
