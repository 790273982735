.tf-primary-font-color {
  --primary-font-color: #000;
}

.tf_form_root {
  width: 100%;
}

.tf_input_label {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1vmax !important;
  color: var(--primary-font-color) !important;
  opacity: 0.5;
  font-weight: 400;
}

.tf_input_label.focused,
.tf_input_label.shrink {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 0.85vmax !important;
  color: var(--primary-font-color) !important;
  opacity: 1;
  margin-left: 0.5vmax
}

.tf_input_label_shrink {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 0.85vmax !important;
  color: var(--primary-font-color) !important;
  opacity: 0.5;
  font-weight: 400;
  margin-left: 5px;
}

.tf_input_text, 
.tf_input_text_center, 
.tf_input_header, 
.tf_input_header_center {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 1vmax !important;
  color: var(--primary-font-color) !important;
  border-radius: 2vmax !important;
}

.tf_input_text_center {
  text-align: center;
}

.tf_input_header {
  font-size: 1vmax !important;
}

.tf_input_header_center {
  font-size: 1.25vmax !important;
  text-align: center;
}

.tf_input_underline_header,
.tf_input_underline {
  color: var(--primary-font-color) !important;
}

.tf_input_underline_header::before,
.tf_input_underline_header::after,
.tf_input_underline::after {
  opacity: 0.5;
}

.tf_input_underline_header::after {
  opacity: 0.3;
}

.tf_input_notch_line {
  border: 2px solid #0146C6 !important;
}