.signup_header {
  height: 15vh;
}

.signup_content {
  height: 85vh;
  display: flex;
  justify-content: center;
}

.signup_banner {
  top: 3vmax;
  left: 0;
  background-color: #0146C7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 4vmax;
  position: absolute;
  overflow: hidden;
}

.signup_logo {
  position: absolute;
  overflow: hidden;
  width: 12vmax;
  padding: 1vmax 2vmax;
  cursor: pointer;
}

.signup_left {
  width: 60%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signup_right {
  width: 40%;
  align-self: center;
  text-align: center;
}

.left_image {
  width: 90%;
  object-fit: contain;
  align-self: flex-end; 
}

.hello_text {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2vmax !important;
  color: #000 !important;
}

.sign_in_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1.5vmax !important;
  color: #000 !important;
}

.social_icon_list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 4% 20%;
}

.social_icon_list_small {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1% 20%;
}

.social_icon {
  height: 2vmax;
  width: 2vmax;
}

.botton_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 4% 20%;
}

.input_field {
  color: #000 !important;
  width: 60% !important;
  align-self: center !important;
}

.forget_pwd_text {
  font-family: 'PoppinsRegular' !important;
  font-size: 1vmax !important;
  color: #000 !important;
}

.success_signup {
  gap: 1vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign_up_success_icon {
  color: #70D291;
  height: 5vmax !important;
  width: 5vmax !important;
}

.sign_up_text_1 {
  font-family: 'PoppinsRegular' !important;
  font-size: 1.25vmax !important;
  color: #000 !important;
}

.sign_up_text_2 {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.25vmax !important;
  color: #000 !important;
}

.sign_up_text_3 {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.25vmax !important;
  color: #0146C7 !important;
}

.signup_duplicate_user {
  font-family: 'PoppinsMedium' !important;
  font-size: 1vmax !important;
  color: #E32636 !important;
}

.sign_up_text_2_line {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 95%;
  height: 0.25vmax;
}

mark {
  -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
          animation: 1.5s highlight 1.5s 1 normal forwards;
  background-color: none;
  background: linear-gradient(90deg, #7efff5 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}