@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0.5vmax !important;
}

:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-dm-sans: "DM Sans";
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
}

.MuiSvgIcon-root {
    font-size: 1.5vmax !important;
}

.all_icon {
    height: 1vmax !important;
    width: 1.5vmax !important;
    color: white;
}

.product {
  position: relative;
  overflow: hidden;
  padding: 0.5vmax;
  width: 100%;
}

.product-container {
  padding: 0 1vw 0 4vw;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.product-container::-webkit-scrollbar {
  display: none;
}

.product-card {
  flex: 0 0 auto;
  width: 30%;
  height: 65vh;
  margin: 0 1vmax;
}

.product-card:hover .card-btn {
  opacity: 1;
}

.pre-btn,
.nxt-btn {
  border: none;
  width: 10vw;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  cursor: pointer;
  z-index: 8;
}

.pre-btn {
  left: 0;
  transform: rotate(180deg);
}

.nxt-btn {
  right: 0;
}

.pre-btn img,
.nxt-btn img {
  opacity: 0.2;
}

.pre-btn:hover img,
.nxt-btn:hover img {
  opacity: 1;
}