.home_header {
  font-family: 'PoppinsSemiBold' !important;
  font-size: 2vmax !important;
  color: #0146C7;
}

.home_summary {
  font-family: 'PoppinsRegular' !important;
  font-size: 2vmax !important;
  color: #000;
  padding: 0 7.5vmax;
  text-align: center;
}

.home_description {
  font-family: 'PoppinsRegular' !important;
  font-size: 1.25vmax !important;
  color: #0146C7;
  padding: 2vmax 12vmax;
  padding-bottom: 1vmax;
  text-align: center;
}

.home_button {
  padding: 1vmax;
}

.home_question {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.5vmax !important;
  color: #01318D;
  padding: 1vmax;
}

.home_scroll {
  font-family: 'PoppinsLight' !important;
  font-size: 1vmax !important;
  color: #0146C7;
}

.scroll_icon {
  height: 2vmax !important;
  color: #0146C7;
  margin: 1vmax;
}

.home_right_bg_image {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding: 2vmax 0;
  border-top-left-radius: 5vmax;
  float: right;
}