/* .service_bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.find_course_text {
  font-family: 'PoppinsRegular' !important;
  color: #000 !important;
  font-size: 1.25vmax !important;
}

.find_course_input {
  width: 15vw;
  border-radius: 1vmax !important;
  background-color: #043897;
  color: #FFFF !important;
}

.find_course_input_text {
  font-family: 'PoppinsMedium' !important;
  font-size: 1vmax !important;
  color: #FFFF !important;
}

.find_course_input_label {
  color: #000 !important;
}

.find_course_icon {
  color: #FFFF !important;
}

.find_course_input_notch_line {
  border: none !important;
}

.find_course_img {
  color: #0948C8 !important;
  font-size: 1vmax !important;
  font-family: 'PoppinsSemiBold' !important;
}

.find_course_link {
  font-family: 'PoppinsSemiBold' !important;
  color: #0948C8 !important;
  font-size: 1vmax !important;
}

.find_close_button {
  color: #000;
}

.find_header {
  font-family: 'PoppinsMedium' !important;
  font-size: 2vmax !important;
  color: #01318D !important;
}

.find_sub_header {
  font-family: 'PoppinsMedium' !important;
  font-size: 1.25vmax !important;
  color: #000 !important;
} */

.find_course_text {
  font-family: 'PoppinsRegular' !important;
  color: #000 !important;
  font-size: 1.25vmax !important;
}

.find_course_input {
  /* width: 20vw; */
  border-radius: 2vmax !important;
  color: #0146C7;
  background-color: #FFFF !important;
}

.find_course_input_text {
  font-family: 'PoppinsMedium' !important;
  font-size: 1vmax !important;
  color: #0146C7 !important;
  border: 2.5px solid #0146C7 !important;
  border-radius: 2vmax !important;
}

.find_course_input_label {
  color: #000 !important;
}

.find_course_icon_bg {
  background-color: #0146C7 !important;
}

.find_course_icon {
  color: #0146C7 !important;
}

.find_course_input_notch_line {
  border: none !important;
}

.rectangle-div {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 28vw;
  height: 0.5vmax;
}
.custom-classes-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.school-sponsored {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.school-1-icon {
  position: relative;
  width: 8vmax;
  height: 8vmax;
  object-fit: cover;
}
.class-frame-wrapper,
.school-sponsored-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.school-sponsored-parent {
  gap: 1vmax;
}
.class-frame-wrapper {
  flex: 1;
  background-color: #FFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 25vh;
  overflow: hidden;
  padding: 1vmax;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.class-frame-parent11 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  gap: 3vmax;
  font-size: 1.25vmax;
  color: #000;
}
.achieve-your-personal,
.class-frame-parent10 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.class-frame-parent10 {
  flex-direction: column;
  justify-content: flex-start;
  gap: 5vmax;
}
.achieve-your-personal {
  position: relative;
  font-size: 1.25vmax;
  font-weight: 600;
  color: #000;
  justify-content: center;
  padding: 0 5vmax;
  flex-shrink: 0;
}
.class-frame-parent9 {
  align-self: stretch;
  justify-content: center;
}
.custom-classes-bottom {
  position: relative;
  font-size: 1.15vmax;
  color: #000;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  gap: 0.5vmax;
}
.need-a-course {
  margin-bottom: 0px !important; 
  font-size: 2vmax;
}
.class-frame-parent9 {
  flex: 1;
  gap: 3vmax;
  padding: 1vmax 0 0 ;
}
.custom-classes,
.custom-classes-inner,
.class-frame-parent9 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-classes-inner {
  align-self: stretch;
  flex: 1;
  justify-content: center;
}
.custom-classes {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 2vmax;
  box-sizing: border-box;
  justify-content: flex-start;
  background-image: url(../../assets/img/custom_class/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 3vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
}
