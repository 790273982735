@font-face {
    font-family: 'PoppinsBold';
    src: local("Poppins-Bold"),
    url("../assets/font/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: local("Poppins-SemiBold"),
    url("../assets/font/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsRegular';
    src: local("Poppins-Regular"),
    url("../assets/font/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsMedium';
    src: local("Poppins-Medium"),
    url("../assets/font/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsLight';
    src: local("Poppins-Light"),
    url("../assets/font/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsExtraLight';
    src: local("Poppins-ExtraLight"),
    url("../assets/font/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'PoppinsThin';
    src: local("Poppins-Thin"),
    url("../assets/font/Poppins/Poppins-Thin.ttf") format("truetype");
}


