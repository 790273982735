.college-consulting {
  align-self: stretch;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.cc-frame-child {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 35vw;
  height: 0.5vmax;
}
.college-consulting-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.our-experts-take {
  margin: 0;
}
.our-experts-take-container {
  align-self: stretch;
  position: relative;
  font-size: 1.25vmax;
  text-transform: uppercase;
  font-weight: 500;
  color: #000;
}
.cc-frame-parent4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3vmax;
}
.lets-work-together {
  align-self: stretch;
  position: relative;
  font-size: 1.75vmax;
  text-transform: uppercase;
  color: #000;
}
.cc-frame-parent3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1vmax;
}
.cc-frame-item {
  position: relative;
  border-radius: 5vmax;
  background-color: #0146c7;
  box-shadow: 0 8px 25px rgba(13, 10, 44, 0.06);
  width: 25vw;
  height: 5vh;
  z-index: 0;
}
.cc-frame-parent2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5vmax;
}
.cc-frame-inner {
  position: relative;
  width: 20vmax;
  height: 20vmax;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.cc-frame-inner-women {
  position: relative;
  width: 20vmax;
  height: 20vmax;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  border-top-right-radius: 50%;
}
.cc-frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.college-consulting,
.cc-frame-parent1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cc-frame-parent1 {
  align-self: stretch;
  flex: 1;
  padding: 2vmax 0 0;
  justify-content: space-between;
}
.college-consulting {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  justify-content: flex-start;
  background-image: url(../../assets/img/college/background1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 3vmax;
  color: #0146c7;
  font-family: 'PoppinsMedium' !important;
}
