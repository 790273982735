.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5vmax;
}

.footerLinks {
  margin-top: 10px; 
}

.footerLinks a {
  text-decoration: none;
  color: #000; 
  margin: 10px;
}

.footerInfo {
  color: #888; 
  margin-top: 10px; 
}
