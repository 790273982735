.ckb-primary-font-color {
  --primary-font-color: #000;
}

.ckb_form_root {
  width: 100%;
  margin: 0;
	padding: 0;
	align-items: flex-end;
}

.ckb_form_root_center {
  width: 100%;
  margin: 0;
	padding: 0;
	align-items: center;
}

.ckb_form_root_left {
  width: 100%;
  margin: 0;
	padding: 0;
	align-items: flex-start;
}

.ckb_form_label {
  padding: 0 !important;
  padding-top: 1vmax !important;
}

.ckb_form_label_left {
  padding: 0 !important;
}

.ckb_label {
  font-family: 'PoppinsRegular' !important;
  font-size: 1vmax !important;
  color: var(--primary-font-color) !important;
  padding: 0 !important;
  margin: 0 !important;
}