.technology-support1-icon,
.technology-support1 {
  align-self: stretch;
  position: relative;
}
.technology-support1 {
  font-size: 3vmax;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  color: #0146c7;
  text-align: center;
}

.class-support,
.class-support-desc {
  align-self: stretch;
  position: relative;
  font-size: 2vmax;
  font-weight: 500;
  color: #0146c7;
}
.class-support-desc {
  font-size: 1vmax;
  color: #000;
}
.class-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25vmax;
  max-width: 25%;
}
.tech-frame-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 2vmax;
}
.tech-frame-child {
  position: relative;
  background: linear-gradient(90deg, #2d6ae6, #12007b);
  width: 58vw;
  height: 0.5vmax;
}
.technology-support1-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.technology-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4vmax 0 0;
}
.technology-support-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex: 1;
  justify-content: space-evenly;
  gap: 3vmax;
}
.technology-support {
  overflow: hidden;
  height: 100vh;
  box-sizing: border-box;
  justify-content: flex-start;
  background-image: url(../../assets/img/technology/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 3vmax;
  color: #01318d;
  font-family: 'PoppinsMedium' !important;
}
